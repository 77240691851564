<template>
  <div class="modals">
    <transition-group enter-to-class="enter" name="modal">
      <div v-for="modal in modals" :key="modal.id" :class="{cell: true, ok: modal.ok}" class="cell">
        <img v-if="!modal.ok" class="icon e" src="/img/cross.svg" />
        <img v-else class="icon ok" src="/img/tick.svg" />
        <div class="modal__info">
          <h2>{{!modal.ok ? 'Error' : 'Success'}}</h2>
          <span>{{ modal.message }}</span>
        </div>
      </div>
    </transition-group>
  </div>
</template>

<script>
export default {
  name: "Modal",
  data() {
    return {
      modals: [],
    };
  },
  methods: {
    new: function (message = "Unknown error", ok) {
      let id = new Date().getTime();
      this.modals.push({
        id,
        message,
        ok
      });
      setTimeout(() => {
        this.modals = this.modals.filter((x) => x.id !== id);
      }, 5000);
    },
  },
};
</script>

<style scoped>
.modals {
  display: flex;
  flex-direction: column-reverse;
  position: fixed;
  right: 20px;
  z-index: 99999;
  gap: 10px;
  bottom: 50px;
  max-height: 200px;
  overflow: hidden;
}

.modals .cell {
  background-color: rgb(33, 41, 53);
  border-radius: 10px;
  position: relative;
  display: flex;
  border-left: 3px solid rgb(255, 79, 79);
  box-shadow: 4px 4px 15px rgba(0, 0, 0, 0.5);
  gap: 17px;
  left: 100%;
  min-width: 310px;
  max-width: 310px;
  align-items: center;
  animation: slide 0.2s forwards;
  font-family: Poppins, sans-serif;
  padding: 10px 18px;
  color: white;
}

.modals .cell.ok {
  border-left: 3px solid rgb(76, 202, 97);
}

.modals .icon {
  width: 22px;
  height: 22px;
  border-radius: 5px;
  padding: 5px;
}

.modals .icon.e {
  background-color: rgb(255, 79, 79);
}
.modals .icon.ok {
  background-color: rgb(69, 185, 88);
}

.modals .modal__info {
  display: flex;
  flex-direction: column;
  color: white;
}

.modals .modal__info h2 {
  margin: 0;
  font-family: Poppins, sans-serif;
  font-weight: 600;
  font-size: 15px;
}

.modals .modal__info span {
  margin: 0;
  font-family: Poppins, sans-serif;
  font-weight: 100;
  font-size: 14px;
}

.modal-leave-active {
  animation: invslide 0.2s forwards !important;
}

@keyframes slide {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-100%);
  }
}

@keyframes invslide {
  to {
    transform: translateX(0);
  }
  from {
    transform: translateX(-100%);
  }
}
</style>
