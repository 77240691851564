<template>
  <header>
    <transition name="fade">
      <div v-if="codePopup" class="code__popup">
        <div class="main__wrap">
          <div ref="code" class="content">
            <h1>
              {{
                {
                  en: "🎉 Activate your code! 🎊",
                  es: "🎉 ¡Canjea tu código! 🎊",
                }[lang]
              }}
            </h1>
            <p>
              {{
                {
                  en: "Support your favourite influencer using their creator code and get unique rewards!",
                  es: "Apoya a tu influencer favorito utilizando su código y obten recompensas únicas!",
                }[lang]
              }}
            </p>
            <span v-if="creatorCode">{{
              {
                en: `Currently using '${creatorCode}' on your purchases`,
                es: `Utilizando código '${creatorCode}' en tus compras`,
              }[lang]
            }}</span>
            <div class="code__input">
              <input @keyup.enter="codeVerify" type="text" maxlength="100" />
              <button v-if="!loadingCode" @click="codeVerify">
                <svg viewBox="0 0 256 512">
                  <path
                    fill="currentColor"
                    d="M17.525 36.465l-7.071 7.07c-4.686 4.686-4.686 12.284 0 16.971L205.947 256 10.454 451.494c-4.686 4.686-4.686 12.284 0 16.971l7.071 7.07c4.686 4.686 12.284 4.686 16.97 0l211.051-211.05c4.686-4.686 4.686-12.284 0-16.971L34.495 36.465c-4.686-4.687-12.284-4.687-16.97 0z"
                  ></path>
                </svg>
              </button>
            </div>
            <div v-if="codeError" class="error__holder">{{ codeError }}</div>
          </div>
        </div>
      </div>
    </transition>
    <div class="navbar">
      <div class="nav__item icon">
        <component
          :is="headerName == 'home' ? 'div' : 'a'"
          href="/"
          class="icon__holder"
        >
          <img src="/icon.png" alt="" />
          <span>Ubilong</span>
        </component>
      </div>
      <div class="nav__item bar">
        <a :class="{ active: headerName == 'home' }" :href="url + '/'">
          <div class="img"><img src="/img/home.svg" /></div>
          <span>{{ { en: "Home", es: "Inicio" }[lang] }}</span>
        </a>
        <a href="https://ubilong.com/#partners">
          <div class="img"><img src="/img/donate.svg" /></div>
          <span>{{ { en: "Donate", es: "Donar" }[lang] }}</span>
        </a>
        <a href="https://ubilong.com/market" :class="{ active: headerName == 'market' }">
          <div class="img"><img src="/img/market.svg" /></div>
          <span>{{ { en: "Market", es: "Tienda" }[lang] }}</span>
        </a>
        <a href="https://ubilong.com/boosters" :class="{ active: headerName == 'boosters' }">
          <div class="img"><img src="/img/booster.svg" /></div>
          <span>Boosters</span>
        </a>
      </div>
      <div class="nav__item user">
        <div
          v-if="!loaded"
          style="width: 50px; height: 50px; border-radius: 50%; margin: 0 10px"
        ></div>
        <button v-if="user?.name" @click="codePopup = true" class="promo__code">
          <div v-if="codemsg && !codemsg.value" class="step__tip">
            {{ { en: "Use your code!", es: "¡Usa tu código!" }[lang] }}
          </div>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            width="512"
            height="512"
          >
            <path
              fill="currentColor"
              d="M20,7H18.262A5.137,5.137,0,0,0,20,3a1,1,0,0,0-2,0c0,2.622-2.371,3.53-4.174,3.841A9.332,9.332,0,0,0,15,3,3,3,0,0,0,9,3a9.332,9.332,0,0,0,1.174,3.841C8.371,6.53,6,5.622,6,3A1,1,0,0,0,4,3,5.137,5.137,0,0,0,5.738,7H4a4,4,0,0,0-4,4v1a2,2,0,0,0,2,2v5a5.006,5.006,0,0,0,5,5H17a5.006,5.006,0,0,0,5-5V14a2,2,0,0,0,2-2V11A4,4,0,0,0,20,7ZM12,2a1,1,0,0,1,1,1,7.71,7.71,0,0,1-1,3.013A7.71,7.71,0,0,1,11,3,1,1,0,0,1,12,2ZM2,11A2,2,0,0,1,4,9h7v3H2Zm2,8V14h7v8H7A3,3,0,0,1,4,19Zm16,0a3,3,0,0,1-3,3H13V14h7Zm-7-7V9h7a2,2,0,0,1,2,2v1Z"
            />
          </svg>
        </button>
        <button @click="notiHandle" v-if="user?.name" class="notification">
          <div
            v-if="
              notifications.length &&
              notifications.some((x) => !user.read.find((s) => x.id == s))
            "
            class="unreads"
          ></div>
          <img id="svg" src="/img/bell.svg" />
        </button>
        <button @click="balHandle" v-if="user?.name" class="wallet">
          $ {{ isNaN(+user.balance) ? 0 : (+user.balance).toFixed(2) }}
        </button>
        <button @click="languageSwap" v-if="!user?.name" class="language">
          <svg viewBox="0 0 496 512">
            <path
              fill="currentColor"
              d="M248 8C111 8 0 119 0 256s111 248 248 248 248-111 248-248S385 8 248 8zm193.2 152h-82.5c-9-44.4-24.1-82.2-43.2-109.1 55 18.2 100.2 57.9 125.7 109.1zM336 256c0 22.9-1.6 44.2-4.3 64H164.3c-2.7-19.8-4.3-41.1-4.3-64s1.6-44.2 4.3-64h167.4c2.7 19.8 4.3 41.1 4.3 64zM248 40c26.9 0 61.4 44.1 78.1 120H169.9C186.6 84.1 221.1 40 248 40zm-67.5 10.9c-19 26.8-34.2 64.6-43.2 109.1H54.8c25.5-51.2 70.7-90.9 125.7-109.1zM32 256c0-22.3 3.4-43.8 9.7-64h90.5c-2.6 20.5-4.2 41.8-4.2 64s1.5 43.5 4.2 64H41.7c-6.3-20.2-9.7-41.7-9.7-64zm22.8 96h82.5c9 44.4 24.1 82.2 43.2 109.1-55-18.2-100.2-57.9-125.7-109.1zM248 472c-26.9 0-61.4-44.1-78.1-120h156.2c-16.7 75.9-51.2 120-78.1 120zm67.5-10.9c19-26.8 34.2-64.6 43.2-109.1h82.5c-25.5 51.2-70.7 90.9-125.7 109.1zM363.8 320c2.6-20.5 4.2-41.8 4.2-64s-1.5-43.5-4.2-64h90.5c6.3 20.2 9.7 41.7 9.7 64s-3.4 43.8-9.7 64h-90.5z"
            ></path>
          </svg>
        </button>
        <button @click="dropHandle" v-if="user?.profileImg" class="avatar">
          <img :src="user?.profileImg || '/icon.png'" />
        </button>
        <a href="/user" v-else-if="!loginPanel" class="join"> Login </a>
        <button @click="hamOpen" class="ham__init">
          <svg viewBox="0 0 448 512">
            <path
              fill="currentColor"
              d="M16 132h416c8.837 0 16-7.163 16-16V76c0-8.837-7.163-16-16-16H16C7.163 60 0 67.163 0 76v40c0 8.837 7.163 16 16 16zm0 160h416c8.837 0 16-7.163 16-16v-40c0-8.837-7.163-16-16-16H16c-8.837 0-16 7.163-16 16v40c0 8.837 7.163 16 16 16zm0 160h416c8.837 0 16-7.163 16-16v-40c0-8.837-7.163-16-16-16H16c-8.837 0-16 7.163-16 16v40c0 8.837 7.163 16 16 16z"
            ></path>
          </svg>
        </button>
      </div>
    </div>
    <div ref="balance" v-if="balPop" class="balance__box">
      <div v-if="!+user?.balance" class="cell nobal">
        {{ { en: "No balance.", es: "Sin fondos." }[lang] }}.
      </div>
      <div v-else class="cell">
        <span class="currency">USD</span>
        <span class="dollars">{{ +user?.balance?.toFixed(3) || 0 }}</span>
      </div>
      <div class="cell">
        <a href="/addbalance" class="balance__add">
          {{ { en: "Add", es: "Agregar" }[lang] }}
        </a>
      </div>
    </div>
    <div ref="notifications" v-if="notiPop" class="notification__box">
      <div v-if="!notifications.length" class="cell noel">
        {{ { en: "No elements here.", es: "Sin elementos aqui." }[lang] }}.
      </div>
      <div
        v-for="noti in notifications"
        :key="noti.id"
        :class="{ cell: true, unread: !user.read.find((x) => x == noti.id) }"
      >
        <h3>{{ noti.title[lang] }}</h3>
        <div
          @mouseover="mouseOver($event, noti)"
          @mouseout="mouseOut($event, noti)"
          v-if="noti.url"
          class="display__image"
        >
          <video v-if="!noti.img" loop muted :src="noti.url"></video>
          <img v-else :src="noti.url" />
        </div>
        <span class="description" v-html="noti.description[lang]"></span>
        <span class="date">{{ noti.date[lang] }}</span>
      </div>
    </div>
    <div ref="dropdown" v-if="dropPop" class="dropdown">
      <a href="/profile" class="cell">
        {{ { en: "Profile", es: "Perfil" }[lang] }}
      </a>
      <a href="/profile#boosters" class="cell">
        Boosters
      </a>
      <a href="/profile#settings" class="cell">
        {{ { en: "Settings", es: "Configuración" }[lang] }}
      </a>
      <div class="divide__slab"></div>
      <button @click="languageSwap" class="cell sec">
        {{ { en: "Language", es: "Idioma" }[lang] }}
      </button>
      <a href="https://discord.gg/33P3bNEG6z" target="_blank" class="cell sec">
        {{ { en: "Support", es: "Soporte" }[lang] }}
      </a>
      <div @click="logout" class="cell sec logout">
        <span>Logout</span>
      </div>
      <div class="divide__slab"></div>
      <div class="cell hor">
        <a href="https://twitter.com/ubilong" target="_blank">
          <img src="/img/twitter.png">
        </a>
        <a href="https://www.instagram.com/ubilongok/" target="_blank">
          <img src="/img/instagram.png">
        </a>
        <a href="https://discord.gg/EYQEWB7BmH" target="_blank">
          <img src="/img/discord.png">
        </a>
      </div>
    </div>
    <div ref="ham" class="ham">
      <div @click="shutHam" class="shut">
        <svg viewBox="0 0 320 512">
          <path
            fill="currentColor"
            d="M193.94 256L296.5 153.44l21.15-21.15c3.12-3.12 3.12-8.19 0-11.31l-22.63-22.63c-3.12-3.12-8.19-3.12-11.31 0L160 222.06 36.29 98.34c-3.12-3.12-8.19-3.12-11.31 0L2.34 120.97c-3.12 3.12-3.12 8.19 0 11.31L126.06 256 2.34 379.71c-3.12 3.12-3.12 8.19 0 11.31l22.63 22.63c3.12 3.12 8.19 3.12 11.31 0L160 289.94 262.56 392.5l21.15 21.15c3.12 3.12 8.19 3.12 11.31 0l22.63-22.63c3.12-3.12 3.12-8.19 0-11.31L193.94 256z"
          ></path>
        </svg>
      </div>
      <div v-if="user?.name" class="profile__ham">
        <img :src="user?.profileImg" />
        <span class="user__display">{{ user?.name }}</span>
      </div>
      <a href="/user" v-else-if="!loginPanel" class="join">
        <span>Login</span>
      </a>
      <div class="ham__btns">
        <button @click="notiHandle" v-if="user?.name" class="notification">
          <div
            v-if="
              notifications.length &&
              notifications.some((x) => !user.read.find((s) => x.id == s))
            "
            class="unreads"
          ></div>
          <img id="svg" src="/img/bell.svg" />
        </button>
        <button v-if="user?.name" @click="codePopup = true" class="promo__code">
          <div v-if="codemsg" class="step__tip">
            {{ { en: "Use your code!", es: "¡Usa tu código!" }[lang] }}
          </div>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            width="512"
            height="512"
          >
            <path
              fill="currentColor"
              d="M20,7H18.262A5.137,5.137,0,0,0,20,3a1,1,0,0,0-2,0c0,2.622-2.371,3.53-4.174,3.841A9.332,9.332,0,0,0,15,3,3,3,0,0,0,9,3a9.332,9.332,0,0,0,1.174,3.841C8.371,6.53,6,5.622,6,3A1,1,0,0,0,4,3,5.137,5.137,0,0,0,5.738,7H4a4,4,0,0,0-4,4v1a2,2,0,0,0,2,2v5a5.006,5.006,0,0,0,5,5H17a5.006,5.006,0,0,0,5-5V14a2,2,0,0,0,2-2V11A4,4,0,0,0,20,7ZM12,2a1,1,0,0,1,1,1,7.71,7.71,0,0,1-1,3.013A7.71,7.71,0,0,1,11,3,1,1,0,0,1,12,2ZM2,11A2,2,0,0,1,4,9h7v3H2Zm2,8V14h7v8H7A3,3,0,0,1,4,19Zm16,0a3,3,0,0,1-3,3H13V14h7Zm-7-7V9h7a2,2,0,0,1,2,2v1Z"
            />
          </svg>
        </button>
      </div>
      <button @click="balHandle" v-if="user?.name" class="wallet">
        $ {{ isNaN(+user.balance) ? 0 : (+user.balance).toFixed(2) }}
      </button>
      <div v-if="!loginPanel" class="divide__slab"></div>
      <a href="https://ubilong.com/profile" class="cell">
        <span>{{ { en: "Profile", es: "Perfil" }[lang] }}</span>
      </a>
      <a href="https://ubilong.com/home" class="cell">
        <span>{{ { en: "Home", es: "Inicio" }[lang] }}</span>
      </a>
      <a href="https://ubilong.com/market" class="cell">
        <span>Market</span>
      </a>
      <a href="https://ubilong.com/boosters" class="cell">
        <span>Boosters</span>
      </a>
      <div @click="logout" class="cell">
        <span>{{ { en: "Logout", es: "Logout" }[lang] }}</span>
      </div>
      <div class="divide__slab"></div>
      <div class="cell hor">
        <div
          @click="languageSwap"
          :class="{ blob: true, disabled: lang == 'es' }"
        >
          {{ { en: "Lenguaje", es: "Language" }[lang] }}
        </div>
      </div>
    </div>
  </header>
</template>

<script>
export default {
  data: function () {
    return {
      name: "header",
      endpoint: "https://ubilong.com/api/v1",
      notiPop: false,
      notifications: [],
      codeError: false,
      creatorCode: null,
      codePopup: false,
      url: "https://ubilong.com",
      loadingCode: false,
      balPop: false,
      acTok: null,
      headerName: null,
      loginPanel: null,
      reTok: null,
      codemsg: null,
      dropPop: false,
      language: {
        home: {
          es: "Inicio",
          en: "Home",
        },
      },
      lang: "es",
      user: {},
    };
  },
  methods: {
    mouseOver: function (e, n) {
      if (n.playing) return;
      const video = e.target.querySelector("video");
      if (!video) return;
      video.play();
      n.playing = true;
    },
    mouseOut: function (e, n) {
      if (!n.playing) return;
      const video = e.target.querySelector("video");
      if (!video) return;
      video.pause();
      video.currentTime = 0;
      n.playing = false;
    },
    rCok: (n) => {
      let name = n + "=";
      let ca = decodeURIComponent(document.cookie).split(";");
      for (let i = 0; i < ca.length; i++) {
        while (ca[i].charAt(0) == " ") ca[i] = ca[i].substring(1);
        if (ca[i].indexOf(name) == 0)
          return ca[i].substring(name.length, ca[i].length);
      }
      return "";
    },
    languageSwap: function () {
      this.lang = this.lang == "es" ? "en" : "es";
      document.cookie = `lang=${this.lang}`;
      this.swap(this.lang);
    },
    parseMarkdown: function (markdownText) {
      const htmlText = markdownText
        .replace(/^### (.*$)/gim, "<h3>$1</h3>")
        .replace(/^## (.*$)/gim, "<h2>$1</h2>")
        .replace(/^# (.*$)/gim, "<h1>$1</h1>")
        .replace(/\*\*(.*?)\*\*/gim, "<span class='bold'>$1</span>")
        .replace(/!\[(.*?)\]\((.*?)\)/gim, "<img alt='$1' src='$2' />")
        .replace(/\[(.*?)\]\((.*?)\)/gim, "<a class='link' href='$2'>$1</a>")
        .replace(/\n$/gim, "<br />");
      return htmlText;
    },
    notiHandle: function () {
      if (this.notiPop == false && this.notifications.length)
        this.notifications = this.notifications.map((x) => {
          return { ...x, date: this.elapsed(x.createdAt) };
        });
      if (this.notiPop == true && this.notifications.length) {
        this.user.read = this.notifications.map((x) => x.id);
        this.readNo();
      }
      this.notiPop = !this.notiPop;
    },
    dropHandle: function () {
      this.dropPop = !this.dropPop;
    },
    balHandle: function () {
      this.balPop = !this.balPop;
    },
    doClick: function (e) {
      if (
        e.target.tagName !== "BUTTON" &&
        this.notiPop &&
        this.$refs.notifications &&
        this.$refs.notifications !== e.target &&
        !this.$refs.notifications.contains(e.target)
      ) {
        if (this.notiPop == true && this.notifications.length) {
          this.user.read = this.notifications.map((x) => x.id);
          this.readNo();
        }
        this.notiPop = false;
      }
      if (
        e.target.tagName !== "BUTTON" &&
        this.dropPop &&
        this.$refs.dropdown &&
        this.$refs.dropdown !== e.target &&
        !this.$refs.dropdown.contains(e.target)
      )
        this.dropPop = false;
      if (
        e.target.tagName !== "BUTTON" &&
        this.balPop &&
        this.$refs.balance &&
        this.$refs.balance !== e.target &&
        !this.$refs.balance.contains(e.target)
      )
        this.balPop = false;
      if (
        this.ham &&
        e.target.tagName !== "BUTTON" &&
        this.$refs.ham &&
        this.$refs.ham !== e.target &&
        !this.$refs.ham.contains(e.target)
      ) {
        this.ham = false;
        this.$refs.ham.style.transform = "none";
      }
      if (
        this.codePopup &&
        e.target.tagName !== "BUTTON" &&
        this.$refs.code &&
        this.$refs.code !== e.target &&
        !this.$refs.code.contains(e.target)
      ) {
        this.codePopup = false;
      }
    },
    logout: function () {
      let re = this.rCok("refresh_token");
      this.dropPop = false;
      this.user = null;
      document.cookie = "access_token=;path=/;max-age=0;domain=.ubilong.com;";
      document.cookie = "refresh_token=;path=/;max-age=0;domain=.ubilong.com;";
      if (!re) return;
      fetch(`${this.endpoint}/auth/logout`, {
        method: "POST",
        body: JSON.stringify({
          refreshToken: re,
        }),
        headers: {
          "Content-Type": "application/json",
        },
      }).catch(console.error);
    },
    swap: function (lang) {
      return lang;
    },
    modal: function (modal) {
      return modal;
    },
    setUser: function (user) {
      return user;
    },
    hamOpen: function () {
      const ham = document.querySelector(".ham");
      ham.style.transform = "translateX(-100%)";
      this.ham = true;
    },
    shutHam: function () {
      const ham = document.querySelector(".ham");
      ham.style.transform = "none";
      this.ham = false;
    },
    handleScroll: function () {
      let header = document.querySelector("header");
      if (!header) return;
      if (
        (window.pageYOffset ||
          document.documentElement.scrollTop ||
          document.body.scrollTop ||
          0) > 2
      ) {
        header.style.backgroundColor = "#131619";
        header.style.boxShadow = "3px 5px 14px rgba(0, 0, 0, 0.2)";
      } else {
        header.style.backgroundColor = "transparent";
        header.style.boxShadow = "none";
      }
    },
    readNo: function () {
      fetch(`${this.endpoint}/user`, {
        method: "PATCH",
        body: JSON.stringify({
          read: this.user?.read || [],
        }),
        headers: {
          Authorization: `Bearer ${this.acTok}`,
          "Content-Type": "application/json",
        },
      }).catch(console.error);
    },
    codeVerify: function () {
      if (this.loadingCode) return;
      const code =
        document.querySelector(".code__popup input")?.value.trim() || null;
      this.codeError = null;
      if (!code)
        return (
          (this.creatorCode = null),
          window.localStorage.removeItem("creator_code"),
          (this.codeError = {
            en: "Please use a valid code",
            es: "Use un código válido",
          }[this.lang])
        );
      if (!this.acTok || !this.user?.name) return;
      this.loadingCode = true;
      fetch(`${this.endpoint}/code/${code}`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${this.acTok}`,
          "Content-Type": "application/json",
        },
      })
        .then(async (rawResponse) => {
          if (rawResponse.ok) {
            let response = await rawResponse.json();
            this.codePopup = false;
            this.loadingCode = false;
            if (response.card)
              return this.modal({
                type: "info",
                description: {
                  en: `Card ${response.card.name} successfully exchanged`,
                  es: `Carta ${response.card.name} canjeada con éxito!`,
                },
              });
            window.localStorage.setItem("creator_code", code.toLowerCase());
            this.modal({
              type: "info",
              description: {
                en: `Code '${code}' updated successfully!`,
                es: `Código '${code}' actualizado con éxito!`,
              },
            });
          } else {
            const contentType = rawResponse.headers.get("content-type");
            let response = contentType.includes("application/json")
              ? await rawResponse.json()
              : await rawResponse.text();
            this.loadingCode = false;
            const res = response.message || response;
            this.codeError = res;
            if (res == "Code not found")
              return (this.codeError = {
                en: "Unvalid code",
                es: "Código inválido",
              }[this.lang]);
            if (res == "Already claimed")
              return (this.codeError = {
                en: "Already claimed code",
                es: "Código ya utilizado",
              }[this.lang]);
            this.modal({
              type: "error",
              description: {
                en: res,
                es: res,
              },
            });
          }
        })
        .catch(console.error);
    },
    reqNots: function () {
      if (!this.acTok) return;
      fetch(`${this.endpoint}/notifications`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${this.acTok}`,
          "Content-Type": "application/json",
        },
      })
        .catch(console.error)
        .then((x) => x.json())
        .then((x) => {
          if (x.results && x.results.length) {
            x.results = x.results.map((Data) => {
              return {
                id: Data.id,
                unread: !!Data.unread,
                url: Data.url ? Data.url : false,
                type: Data.type,
                title: {
                  en: Data.title.en,
                  es: Data.title.es,
                },
                description: {
                  en: this.parseMarkdown(Data.description.en),
                  es: this.parseMarkdown(Data.description.es),
                },
                date: this.elapsed(new Date(Data.createdAt)),
                createdAt: new Date(Data.createdAt),
              };
            });
            x.results = x.results.sort(
              (a, b) => b.createdAt.getTime() - a.createdAt.getTime()
            );
            this.notifications = x.results;
          }
        });
    },
    getUsr: function () {
      return fetch(`${this.endpoint}/user`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.acTok}`,
        },
      })
        .then(async (rawResponse) => {
          let response = await rawResponse.json();
          if (rawResponse.ok) {
            this.user = response;
            this.setUser(this.user);
          } else {
            this.user = {};
          }
        })
        .catch(() => {
          this.user = {};
        });
    },
    elapsed: function (date) {
      let seconds = Math.floor((new Date() - date) / 1000);
      let interval = seconds / 2592000;
      if (interval > 1)
        return {
          en: date.toLocaleDateString(),
          es: date.toLocaleDateString(),
        };
      interval = seconds / 86400;
      if (interval > 1)
        return {
          en: `${Math.floor(interval)} days ago`,
          es: `Hace ${Math.floor(interval)} días`,
        };
      interval = seconds / 3600;
      if (interval > 1)
        return {
          en: `${Math.floor(interval)} hours ago`,
          es: `Hace ${Math.floor(interval)} horas`,
        };
      interval = seconds / 60;
      if (interval > 1)
        return {
          en: `${Math.floor(interval)} minutes ago`,
          es: `Hace ${Math.floor(interval)} minutos`,
        };
      return {
        en: `${Math.floor(seconds) <= 0 ? 0 : Math.floor(seconds)} seconds ago`,
        es: `Hace ${
          Math.floor(seconds) <= 0 ? 0 : Math.floor(seconds)
        } segundos`,
      };
    },
  },
  unmounted() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  created() {
    window.addEventListener("scroll", this.handleScroll);
    this.acTok = this.rCok("access_token");
    this.getUsr();
    this.reqNots();
    const eventHandler = new EventSource(`https://ubilong.com/api/v1/events`, {
      withCredentials: true,
    });
    eventHandler.onmessage = (event) => {
      let Data = JSON.parse(event.data);
      if (Data && !(Data instanceof Error) && Data.is == "notification") {
        if (this.notifications.length)
          this.notifications = this.notifications.map((x) => {
            return { ...x, date: this.elapsed(x.createdAt) };
          });
        if (Data.type == "balance") {
          this.modal({
            type: "info",
            description: Data.description,
          });
          this.user.balance += Data.balance || 0;
        }
        Data = {
          id: Data.id || Data._id,
          unread: Data.unread ? true : false,
          url: Data.url ? Data.url : false,
          img: Data.img ? Data.img : false,
          type: Data.type,
          title: {
            en: Data.title.en,
            es: Data.title.es,
          },
          description: {
            en: this.parseMarkdown(Data.description.en),
            es: this.parseMarkdown(Data.description.es),
          },
          date: this.elapsed(new Date(Data.createdAt)),
          createdAt: new Date(Data.createdAt),
        };
        this.notifications.push(Data);
        this.notifications = this.notifications.map((x) => {
          if (this.user.read.some((l) => l == x.id))
            return { ...x, ...{ unread: false } };
          return x;
        });
        this.notifications = this.notifications.sort(
          (a, b) => b.createdAt.getTime() - a.createdAt.getTime()
        );
      }
    };
    this.loaded = true;
    document.addEventListener("click", this.doClick);
  },
  mounted() {
    this.handleScroll();
    this.creatorCode =
      window.localStorage.getItem("creator_code")?.toLowerCase() || "";
    this.codemsg = { value: window.localStorage.getItem("code_suggest") };
    if (this.codemsg && !this.codemsg.value)
      setTimeout(() => {
        window.localStorage.setItem("code_suggest", true);
        this.codemsg.value = { value: true };
      }, 60000);
  },
};
</script>

<style>
@import "../assets/style.css";
</style>