<template>
  <footer>
    <div class="row">
      <div class="copy">
        <p class="copyright-text">Copyright &copy; {{ date }}<br />Ubilong LTD.</p>
      </div>
      <div class="information">
        <div class="cell">
          <a href="/about">{{ language[lang].company }}</a>
        </div>
        <div class="divider"></div>
        <div class="cell"><a href="/faq">F.A.Q.</a></div>
        <div class="divider"></div>
        <div class="cell">
          <a href="/tos">ToS</a>
        </div>
        <div class="divider"></div>
        <div class="cell">
          <a href="https://discord.gg/33P3bNEG6z" target="about:blank">{{
            language[lang].support
          }}</a>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  data: function () {
    return {
      name: "footer",
      date: new Date().getFullYear(),
      language: {
        es: {
          company: "Compañia",
          support: "Soporte",
        },
        en: {
          company: "Company",
          support: "Support",
        },
      },
      lang: "es",
    };
  },
};
</script>

<style>
[data-load="true"] {
  min-width: 30px;
  background-color: rgb(54 53 53);
  border-radius: 50px;
  display: inline-block;
  box-shadow: 4px 5px 10px rgba(0, 0, 0, 0.2);
  position: relative;
  overflow: hidden;
}

.copy {
  position: relative;
  color: rgba(221, 221, 221, 0.575);
  font-size: 16px;
  margin: 0 auto;
  font-weight: 300;
  font-family: Poppins, sans-serif;
}

@keyframes shimmer {
  100% {
    transform: translateX(100%);
  }
}

[data-load="true"]:after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  transform: translateX(-100%);
  background-image: linear-gradient(
    90deg,
    transparent 0%,
    rgba(255, 255, 255, 0.05) 20%,
    rgba(255, 255, 255, 0.2) 60%,
    transparent 100%
  );
  animation: shimmer 2s infinite;
  content: "";
}

::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar * {
  background: transparent;
}

::-webkit-scrollbar-thumb {
  background-color: rgb(20, 20, 20) !important;
  border: 1px solid rgb(44, 44, 44);
  border-radius: 4px;
}

* {
  outline: none;
  box-sizing: border-box;
}
footer {
  width: 100%;
  box-shadow: -3px -4px 14px rgba(0, 0, 0, 0.227);
  position: absolute;
  bottom: 0;
  background-color: #13161a;
}

footer .information {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
}

footer .divider {
  width: 3px;
  height: 3px;
  margin: 0 15px;
  border-radius: 3px;
  background-color: #d57aff;
}

footer .information a {
  text-decoration: none;
  padding: 8px 12px;
  transition: 0.3s;
  color: #d57aff;
  font-size: 14px;
  border: 1px solid transparent;
  border-radius: 10px;
  font-weight: 300;
  font-family: Poppins, sans-serif;
}

footer .information a:hover {
  color: #d57aff;
  border: 1px solid #d57aff;
}

/* -- FOOTER */

@media (max-width: 700px) {
  footer .information {
    flex-direction: column;
  }

  .nav__item.user > *:not(.ham__init):not(.ham__init svg):not(.ham__init path) {
    display: none;
  }

  .ham__init {
    display: block !important;
  }

  .nav__item.bar {
    display: none;
  }

  footer .information .divider {
    display: none;
  }

  footer .information .cell {
    margin: 10px 0;
  }
}
</style>